@import "./colorPalet";
/* ==========================================================================
   IMPORT GOOGLE FONTS
   ========================================================================== */
@import url('https://fonts.googleapis.com/css?family=Poppins:400,600|Roboto');

/* ==========================================================================
  GENERAL
  ========================================================================== */
body {
    font-family: 'Roboto', sans-serif;
    color: #a0a6ad;
    font-size: 0.875rem;
    font-weight: 400;
    background: #fff;
    overflow-x: hidden;
}

html {
    overflow-x: hidden;
}

p {
    font-size: 0.875rem;
    color: #a0a6ad;
    line-height: 1.625rem;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 0;
}

a:hover, a:focus {
    color: $primaryColor;
}

a {
    color: $primaryColor;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

ul {
    margin: 0;
    padding: 0;

    li {
        list-style: none;
    }
}

a:hover,
a:focus {
    text-decoration: none;
    outline: none;
}

a:not([href]):not([tabindex]) {
    color: #fff;
}

a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
    color: #fff;
}

.bg-transparent {
    background: transparent !important;
}

.bg-defult {
    background: $primaryColor !important;
}

/* ==========================================================================
   Section Title
   ========================================================================== */
.section {
    padding: 5rem 0 4.0625rem;
}

.section-header {
    color: white;
    margin-bottom: 3.125rem;
    text-align: center;
    position: relative;

    .section-title {
        font-size: 1.875rem;
        margin-bottom: 1.25rem;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        background-color: $secondaryColor;
        background-image: -webkit-linear-gradient(bottom right, $primaryColor, $secondaryColor);
        background-image: -o-linear-gradient(bottom right, $primaryColor, $secondaryColor);
        background-image: linear-gradient(to top left, $primaryColor, $secondaryColor);
        position: relative;
    }

}

/* ==========================================================================
   Buttons
   ========================================================================== */
.btn {
    font-size: 0.875rem;
    padding: 0.625rem 1.875rem;
    border-radius: 1.875rem;
    font-family: 'Poppins', sans-serif;
    letter-spacing: .0625rem;
    font-weight: 600;
    color: #fff;
    border: none;
    text-transform: uppercase;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    display: inline-block;
}

.btn:focus,
.btn:active {
    box-shadow: none;
    outline: none;
}

.btn-common {
    background-image: -moz-linear-gradient(0deg, rgb(60, 150, 255) 0%, rgb(45, 251, 255) 100%);
    background-image: -webkit-linear-gradient(0deg, rgb(60, 150, 255) 0%, rgb(45, 251, 255) 100%);
    background-image: -ms-linear-gradient(0deg, rgb(60, 150, 255) 0%, rgb(45, 251, 255) 100%);
    position: relative;
    z-index: 1;

    &:hover {
        color: #fff;
        box-shadow: 0 .5rem .5625rem 0 rgba(96, 94, 94, 0.17);
    }
}

.btn-effect {
    overflow: hidden;
}

.btn-effect:after {
    content: '';
    position: absolute;
    width: 0;
    height: 100%;
    z-index: 1;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.btn-effect:hover:after {
    width: 100%;
}

.btn-border {
    border-style: solid;
    border-width: .125rem;
    cursor: pointer;
    background-color: transparent;
    border-color: $primaryColor;
    color: $primaryColor;

    &:hover {
        box-shadow: 0 .5rem .5625rem 0 rgba(96, 94, 94, 0.17);
    }
}

.btn-border-filled {
    cursor: pointer;
    background-color: #fff;
    border: .125rem solid #fff;
    color: $primaryColor;

    &:hover {
        color: $primaryColor;
        background-color: #ffffff;
        box-shadow: 0 .5rem .5625rem 0 rgba(96, 94, 94, 0.17);
    }
}

.btn-lg {
    padding: 0.875rem 2.0625rem;
    text-transform: uppercase;
    font-size: 1rem;
}

.btn-rm {
    color: $primaryColor;
    padding: 0.625rem 0;
    text-transform: capitalize;
    font-size: 1rem;

    i {
        vertical-align: middle;
    }
}

.btn-trial {
    background: #fff;
    color: #191c1e;
    padding: .3125rem 1.25rem;
    margin-left: 1.875rem;
}

.btn-trial:hover,
.btn-trial:active,
.btn-trial:focus {
    color: $primaryColor;
}

.btn-apple {
    border-width: .125rem;
    border-color: rgb(60, 150, 255);
    border-style: solid;
    background: linear-gradient(to right, $primaryColor 0%, $secondaryColor 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;

    i {
        background: linear-gradient(to right, rgb(60, 150, 255) 0%, rgb(45, 251, 255) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

.btn-apple:hover,
.btn-apple:active,
.btn-apple:focus {
    background: linear-gradient(to right, $primaryColor 0%, $secondaryColor 100%) !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
}

button:focus {
    outline: none !important;
}

.btn-border-fill {
    background: #fff;
    border-color: #fff;
    color: $primaryColor;
}

.btn-subtitle, .btn-subtitle:hover {
    color: #191c1e;
    font-family: 'Poppins', sans-serif;
    background: $tertiaryColor;
    text-align: center;
    border-radius: 1.875rem;
    padding: 0.375rem 1.875rem;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 1.25rem;
    font-size: 0.875rem;
}

.btn-subtitle-cursor {
    cursor: default;
}

.clear {
    clear: both;
}

.mt {
    margin-top: 5rem;
}

.mt-30 {
    margin-top: 1.875rem;
}

.mb-30 {
    margin-bottom: 1.875rem;
}

.mb-50 {
    margin-bottom: 3.125rem;
}

.padding-0 {
    padding: 0;
}

/* ==========================================================================
   Social Iocns
   ========================================================================== */
.social-icons {
    margin-bottom: 1.25rem
}

.social-icons ul {
    margin: 0;
    padding: 0;
    list-style: none
}

.social-icons ul li {
    display: inline
}

.social-icons ul li a {
    display: inline-block;
    margin-left: .3125rem;
    margin-right: .3125rem;
    margin-bottom: 0.9375rem;
    border-radius: .25rem;
    border: .0625rem solid rgba(255, 254, 254, 0.07);
    line-height: 2.5rem;
    width: 2.5rem;
    height: 2.5rem;
    text-align: center;
    font-size: 1rem
}

.social-icons ul li a:hover {
    color: #fff
}

.facebook a {
    color: #4867AA
}

.facebook a:hover {
    background: #4867AA
}

.twitter a {
    color: #1DA1F2
}

.twitter a:hover {
    background: #1DA1F2
}

.google-plus a {
    color: #DD4D42
}

.google-plus a:hover {
    background: #DD4D42
}

.youtube a {
    color: #DF2926
}

.youtube a:hover {
    background: #DF2926
}

.linkedin a {
    color: #007BB6
}

.linkedin a:hover {
    background: #007BB6
}

.pinterest a {
    color: #BD081C
}

.pinterest a:hover {
    background: #BD081C
}

.dribbble a {
    color: #EA4C89
}

.dribbble a:hover {
    background: #EA4C89
}

.behance a {
    color: #0B7CFF
}

.behance a:hover {
    background: #0B7CFF
}

/* Preloader */
#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 9999999;
}

.loader {
    top: 50%;
    width: 3.125rem;
    height: 3.125rem;
    border-radius: 100%;
    position: relative;
    margin: 0 auto;
}

#loader-1:before, #loader-1:after {
    content: "";
    position: absolute;
    top: -0.625rem;
    left: -0.625rem;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border: 0.4375rem solid transparent;
    border-top-color: $primaryColor;
}

#loader-1:before {
    z-index: 100;
    animation: spin 2s infinite;
}

#loader-1:after {
    border: 0.4375rem solid #fafafa;
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/* ==========================================================================
 Scroll To Up
 ========================================================================== */
.back-to-top {
    position: fixed;
    bottom: 1.125rem;
    right: 0.9375rem;
    width: 2.8125rem;
    height: 2.8125rem;
    line-height: 3.125rem;
    cursor: pointer;
    text-align: center;
    border-radius: .25rem;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 .5rem .5625rem 0 rgba(96, 94, 94, 0.17);
    z-index: 1000;
}

.back-to-top i {
    background: linear-gradient(to right, rgb(60, 150, 255) 0%, rgb(45, 251, 255) 100%);
    -webkit-background-clip: text;
    font-size: 1.25rem;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
