@import "./colorPalet";

/* ==========================================================================
   Footer Style
   ========================================================================== */
   .block-title{
    font-size: 1.25rem;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 1.25rem;
    position: relative;
   }
   footer{
    .footer-Content{
      background-image: -moz-linear-gradient( 0deg, rgb(60,150,255) 0%, rgb(45,251,255) 100%);
      background-image: -webkit-linear-gradient( 0deg, rgb(60,150,255) 0%, rgb(45,251,255) 100%);
      background-image: -ms-linear-gradient( 0deg, rgb(60,150,255) 0%, rgb(45,251,255) 100%);
      padding: 3.75rem 0 0;
      color: #546E7A;
      h2{
        color: #ffffff;
        font-size: 1.875rem;
      }
    }
   }
   .textwidget{
    line-height: 1.5rem;
    margin-bottom: 0.625rem;
    p{
      font-size: 0.875rem;
      color: #ffffff;
    }
   }
   footer .menu{
    padding-left: 0;
    li{
      padding-bottom: .75rem;
      a{
        color: #ffffff;
        font-size: 0.875rem;
        &:hover{
          color: #f1f1f1;
        }
      }
    }
   }

 .footer-social{
  margin-top: .25rem;
  li{
    display: inline-block;
    margin-right: 0.9375rem;
    a{
      color: #fff;
      font-size: 0.875rem;
      text-align: center;
      display: inline-block;
      -webkit-transition: all .4s ease-in-out;
      -moz-transition: all .4s ease-in-out;
      transition: all .4s ease-in-out;
    }
    .facebook:hover{
      color: #3b5998;
    }
    .twitter:hover {
      color: #55acee;
    }
    .linkedin:hover {
      color: #007bb5;
    }
    .google-plus:hover {
      color: #dd4b39;
    }
  }
 }
 .copyright{
    padding: 0.9375rem 0;
    border-top: .0625rem solid rgba(255, 255, 255, 0.3);
    color: #ffffff;
    margin-top: 1.875rem;
  p{
    margin-bottom: 0;
    color: #fff;
    a{
      color: #ffffff;
      &:hover{
        color: #f1f1f1;
      }
    }
  }
  .nav-inline{
    .nav-link{
      color: #ffffff;
      padding: 0.625rem 0;
      margin-left: 0.625rem;
      &:hover{
        color: $primaryColor;
      }
    }
  }
 }
