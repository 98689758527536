/* You can add global styles to this file, and also import other style files */
@import "bootstrap/scss/bootstrap.scss";
@import "scss/main";

.menu-bg {
    background: -webkit-linear-gradient(left, $primaryColor 0%, $secondaryColor 100%) !important;
    background: linear-gradient(to right, $primaryColor 0%, $secondaryColor 100%) !important;
    box-shadow: 0 0 0.4375rem .0625rem rgba(0, 0, 0, 0.1);
    z-index: 99;
    padding: .3125rem;

    .menu-button {
        top: 0;

        &:hover {
            cursor: pointer;
            color: $primaryColor;
        }
    }

    .logo-menu a {
        color: $primaryColor;
    }

    .navbar-nav .nav-link {
        color: #ffffff !important;

        &:hover {
            color: #ffffff !important;
        }
    }

    .navbar-nav .active {
        color: #ffffff !important;
    }
}

.component {

    &:nth-child(odd) {
        .section {
            background-color: $limeFourth;
            color: #000;
        }
    }

    &:nth-child(even) {
        .section {
            color: #555;
        }
    }

    .content {
        padding: 1rem 0;
        text-align: justify;
        //color: #000;

        &:first-letter {
            margin-left: 3rem;
            font-size: large;
            color: $primaryColor;
            font-weight: 700;
        }

        ul {
            margin-left: 3rem;
            padding-top: 1rem;

            li {
                list-style-type: disc;
                padding: .5rem 0;

                &.smaller {
                    padding: .1rem 0;
                }
            }
        }
    }
}


.help {
    border-bottom: dotted .125rem $primaryColor;
}

a {
    color: $primaryColor;
    text-decoration: underline;
}


@media (max-width: 575px) {
    .content {
        padding: 1rem !important;

        ul {
            margin-left: 1rem;
        }
    }

    .header-area {
        .head-title {
            font-size: 2rem !important;
        }
    }

    .nav-item {
        padding-left: 1.5rem !important;
    }

    .section {
        padding: 4rem 0 1rem 0;

        .section-header {
            margin-bottom: 1rem;
        }
    }

    textarea {
        max-height: 10vh;
    }
}
